<template>
  <div class="msg">
    <!-- <div class="tip">
        <div>消息小助手：点击关注立即接受社区动态</div>
        <img src="~@/assets/img/jt_fr.png">
    </div>
    <div class="height"></div>-->
    <ul>
        <li @click="letter">
            <div><img src="~@/assets/img/news-ico1.png">私信</div>
            <span></span>
        </li>
        <li @click="$router.push('/liketome')">
            <div><img src="~@/assets/img/news-ico2.png">点赞我的</div>
            <span></span>
        </li>
        <li @click="$router.push('/followtome')">
            <div><img src="~@/assets/img/news-ico3.png">关注我的</div>
            <span></span>
        </li>
    </ul>
    <div class="comment">
        <div class="comment_tit">评论我的</div>
        <div class="comment_list" style="min-height:1rem">
<vue-scroll class="main-page" :ops="ops"
      @refresh-start="handleRefresh"
      @load-start="handleLoad"
       ref="vs"
    >
            <div class="comment_li" v-for="item in posts" :key="'msg-to-me'+item.id" @click="$router.push('/post/'+item.post.id)">
                <div class="c_fl">
                    <div class="tx" :style="{'background-image':'url('+item.user.headimgurl+')'}"></div>
                    <div class="info">
                        <div class="name">{{item.user.title}}</div>
                        <div class="reply" v-html="item.msg"></div>
                        <p>{{item.create_time}}</p>
                    </div>
                </div>
            </div>
</vue-scroll>
        </div>
    </div>
    <Navbar nav="msg" />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Api from '@/api'

import Vue from 'vue'
export default {
  name: 'msg',
  data(){
      const ops = {
      vuescroll: {
        mode: 'slide',
        pullRefresh: {
          enable: true
        },
        pushLoad: {
          enable: true,
        }
      }
    };
   
      ops.vuescroll.pullRefresh.tips = {
        deactive: '下拉刷新',
        active: '释放刷新',
        start: '刷新中...',
        beforeDeactive: '刷新成功!'
      };
      ops.vuescroll.pushLoad.tips = {
        deactive: '上拉加载',
        active: '释放加载',
        start: '加载中...',
        beforeDeactive: '加载成功!'
      };
    return {
      posts:[],
       page:1,
       ops:ops,
       isloading:false,
    }
  },
  components: {
    Navbar
  },
  mounted(){
    var that=this
    Api.go('ping',{test:111},(res)=>{
      console.log(res,'bb',res.title)
      that.title=res.title
    }) 
    this.refreshPost()
  },
  methods:{
      letter(){
          this.$router.push('/LetterList')
      }
      ,
    handleRefresh(vm, refreshDom, done){
      if(this.isloading){return;}
      console.log('refresh')
      this.page=1;
      this.posts=[];
      this.refreshPost(done)
    },
    handleLoad(vm, refreshDom, done){
      if(this.isloading){return;}
      console.log('handleLoad')
      this.refreshPost(done)
    },
    refreshPost(cb){
      var that=this
      this.isloading=true
      
      Api.go('comment_to_me',{page:this.page++},(res)=>{
        var _=that.posts
        if(res.length>0){
          for(var i in res){
            _.push(res[i])
          }
        }else{
            if(this.page>1){
                Vue.prototype.$toast('暂无更多');}
            }
      
            if(cb){cb();}
            that.isloading=false;
            setTimeout(() => {
            that.$refs.vs.refresh();
            }, 100)
      }) 
    }
  }
}
</script>

<style lang="less" scoped>
    .tip{
        width: 100%;
        height: .8rem;
        background: #FFEBD9;
        padding: 0 .32rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        position: fixed;
        top: 0;
        left: 0;
        div{
            font-size: .24rem;
            color: #666;
            background: url(~@/assets/img/tip.png) no-repeat left top/.32rem .32rem;
            padding-left: .4rem;
        }
        img{
            width: .24rem;
            height: .24rem;
        }
    }
    .height{
        height: .8rem;
    }
    .msg{   
        ul{
          background: #fff;
           padding:0 .32rem; 
           li{
               display: flex;
               justify-content: space-between;
               align-items: center;
               padding: .2rem 0;
               div{
                   font-size: .28rem;
                   color: #333;
                   display: flex;
                   align-items: center;
                   img{
                       width: .8rem;
                       height: .8rem;
                       display: block;
                       margin-right: .24rem;
                   }
               }
               span{
                   width: .36rem;
                   height: .36rem;
                   background: url(~@/assets/img/my-jt.png) no-repeat left top/.32rem .32rem;
               }
           }
        }
        .comment{
            background: #fff;
            .comment_tit{
                font-size: .34rem;
                color: #333;
                font-weight: bold;
                padding:.16rem .32rem;
                border-bottom: 1px solid rgba(0, 0, 0, .1);
            }
            .comment_list{
                padding:0 .32rem;
                .comment_li{
                    display: flex;
                    justify-content: space-between;
                    padding: .2rem 0;
                    .c_fl{
                        display: flex;
                        .tx{
                            width: .8rem;
                            height: .8rem;
                            background: #ccc;
                            border-radius: 50%;
                            margin-right: .12rem;
                            background-size: cover;
                        }
                        .info{
                            .name{
                                font-size: .3rem;
                                color: #333;
                            }
                            .reply{
                                font-size: .26rem;
                                color: #777;
                                span{
                                    color: #F65102;
                                }
                            }
                            p{
                                font-size: .22rem;
                                color: #aaa;
                            }
                        }
                    }
                    .c_pic{
                        width: 1.2rem;
                        height: 1.2rem;
                        border-radius: .1rem;
                        background: red;
                    }
                }
            }
        }

    }
</style>